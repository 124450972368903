.main-blue-gradient-button {
	display: inline-block;
	height: 40px;
	padding: 0 16px;
	margin: 0 12px;
	font-weight: 600;
	line-height: 24px;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	cursor: pointer;
	background: var(--Blue-400);
	border-radius: 4px;
	color: var(--White);
	font-size: 16px;
	transition: box-shadow 0.25s;
	border: none;
	font-family: inherit;
	transition: 0.2s all;
}

.main-blue-gradient-button:hover:not([disabled]) {
	color: var(--White);
	background: var(--Blue-300);
}

.main-blue-gradient-button:active {
	filter: brightness(85%);
}

.main-blue-gradient-button:disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

.small-button {
	height: unset;
	padding: 4px 12px;
}

.main-blue-gradient-button.inactive {
	opacity: 50%;
	pointer-events: none;
}

.main-blue-gradient-border-button {
	width: fit-content;
	height: 40px;
	display: inline-block;
	padding: 0 16px;
	margin-right: 12px;
	font-weight: 600;
	font-size: 16px;
	font-family: "Poppins";
	text-align: center;
	white-space: nowrap;
	line-height: 24px;
	touch-action: manipulation;
	cursor: pointer;
	border-radius: 4px;
	color: var(--White);
	border: solid 0.125rem var(--Blue-400);
	box-sizing: border-box;
	background-color: transparent;
	transition: 0.2s all;
	gap: 8px;
}

.main-blue-gradient-border-button:hover:not([disabled]) {
	border: solid 0.125rem var(--Blue-200);
	background-color: rgba(0, 145, 192, 0.2);
}

.main-blue-gradient-border-button:disabled {
	opacity: 0.5;
	border: solid 0.125rem var(--Blue-200);
	pointer-events: none;
	touch-action: none;
}

.main-blue-gradient-border-button:active,
.main-blue-gradient-border-button:focus {
	border: solid 0.125rem var(--Blue-200) !important;
	filter: brightness(85%);
	background-color: transparent !important;
}
