.borderless-input {
	height: 38px;
	margin: 46px;
	margin-left: 0px;
	margin-right: 0px;
	border-bottom: 2px solid #0dbff9;
}

.borderless-input > img {
	float: left;
	width: 35px;
	padding-left: 9px;
	width: 26px;
}

.borderless-input > input {
	width: 62%;
	float: left;
	margin-left: 15px;
	height: 100%;
	background: transparent;
	border: none;
	color: white;
	font-size: 25px;
	font-family: inherit;
}
