.level-row {
	display: flex;
	padding-right: 115px;
}
.user-card {
	width: 210px;
}

.level-grid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	margin-left: 56px;
	grid-gap: 40px;
}

.level-item {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 0 15px;
}

.level-label {
	color: white;
	font-size: 16px;
	font-weight: 500;
	word-wrap: break-word;
	padding-left: 16px;
	line-height: 24px;
}

input[type="checkbox"] {
	height: 35px;
	display: flex;
	justify-content: center;
}

/* tablet screen*/
@media only screen and (max-width: 1300px) {
	.level-grid {
		margin-left: 48px;
		row-gap: 24px;
		column-gap: 32px;
		white-space: nowrap;
		overflow: hidden;
	}
}
