ol,
ul {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

.list-group-item {
	position: relative;
	padding: 10px 15px;
	margin-bottom: -1px;
	vertical-align: top;
}

/** TODO: when setting the custom gmap overlay class move these definitions to its css */
.popup-bubble {
	position: absolute;
	top: 0;
	left: 0;
	transform: translate(-50%, -100%);

	background-color: #051c2a;
	border-color: #051c2a;
	color: white;

	border-radius: 5px;
	padding: 5px;
}

.popup-bubble-anchor {
	position: absolute;
	width: 100%;
	bottom: 8px;
	left: 0;
}

.popup-container {
	cursor: auto;
	height: 0;
	position: absolute;
	width: 200px;
}
