@import url("resources/css/main.css");
@import url("resources/css/polyfills.css");

@import url("resources/css/atomic.css");

@import url("resources/css/inputs/general.css");
@import url("resources/css/inputs/borderless-input.css");

@import url("resources/css/buttons/blue-button.css");
@import url("resources/css/buttons/checkbox.css");
@import url("resources/css/buttons/round-checkbox.css");
@import url("resources/css/inputs/black-plain-input.css");
@import url("resources/css/inputs/blue-plain-input.css");

@import url("resources/css/lists/general.css");

@import url("resources/css/screens/user.css");
@import url("resources/css/screens/login.css");
@import url("resources/css/screens/config.css");

@import url("resources/css/dialogs/general.css");

:root {
	/*PRIMARY*/
	--Blue-100: #9ee5fd; /*light*/
	--Blue-200: #0dbff9; /*Glow*/
	--Blue-300: #08ade2;
	--Blue-400: #0091c0; /*Default*/
	--Blue-500: #04394b;
	--Darkblue-50: #173856;
	--Darkblue-100: #112d41; /*Selected*/
	--Darkblue-200: #092536;
	--Darkblue-300: #051c2a; /*Hover*/
	--Darkblue-400: #041520;
	--Darkblue-500: #080809;
	--White: #ffffff;
	--Gray-100: #ddd9d9;
	--Gray-200: #8b8b8b;
	--Black: #000000;
	--Divider-white-15percent: rgba(255, 255, 255, 0.15);

	--Red: #dd3220;
	--Green: #00b45d;

	/*SECONDARY*/
	--Orange-100: #f9650d;
	--Orange-200: #d95c12;
	--BlueGray-200: #303e48;
	--BlueGray-200-50: rgba(48, 62, 72, 0.5);

	/* Gradients */
	--Bright-Blue: linear-gradient(to left, #44e0fe 0%, #00a9e1 40%, #0091c0 100%);
	--Blue-Purple: linear-gradient(to left, #7a73ff 4%, #44e0fe 100%);
	--Darkblue-light-top: linear-gradient(to bottom, #0c4a6c 0%, #000506 100%);
	--Darkblue-light-bottom: linear-gradient(to top, #0c4a6c 0%, #041520 100%);
	--Darkblue-soft-top: linear-gradient(to bottom, #122f43 0%, #041520 100%);
	--Darkblue-radial: radial-gradient(95.03% 100% at 1.21% 0%, #112d41 0%, #041520 100%);

	/* Hover layers */
	--Dark-blue-100-30percent: rgba(17, 45, 65, 0.3);
	--BlueGray-200-50percent: rgba(48, 62, 72, 0.5);
	--Primary-Hover-White: rgba(255, 255, 255, 0.1);
	--Semantic-Hover-BrightColor: rgba(0, 145, 192, 0.2);
}

.initial-load-spinner {
	width: 48px;
	height: 48px;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

/* ESRI */

/* needed so esri native popups are above IRIS map labels
   cannot be set on the respective CSS as it's an element created inside map container outside the Angular component
*/
.esri-ui {
	z-index: 1 !important;
}

mark {
	background: #3272a780;
	color: white;
}
