.card-list-container {
	padding-left: 2.5%;
	padding-right: 2.5%;
	overflow: hidden auto;
	height: 561.137px;
	max-height: 561.137px;
}

.item-card {
	/* background-image: linear-gradient(to bottom, #0c4a6c, #000506 13%); */
	background-color: var(--Darkblue-300);
	padding: 10px;
	padding-top: 26px;
	position: relative;
	transition: 0.05s box-shadow;
}

.item-card.active {
	outline: 1px solid #49daf1;
}

.item-card:hover {
	box-shadow: 0 1px 8px 2px rgba(12, 181, 255, 0.5);
}

.item-card-title {
	margin-top: -18px;
	color: white;
	font-size: 18px;
	text-align: center;
	font-weight: bold;
	width: 100%;
}

.item-card-title > input {
	text-align: center;
	font-size: 20px;
	margin-bottom: 4px;
	text-transform: uppercase;
	color: white;
	font-weight: bold;
	padding: 2px;
	padding-left: 5px;
	border: none;
	height: 23px;
	border-radius: 3px;
	background-color: transparent;
	padding-left: 3px;
	width: 100%;
}

.item-card-title > input:focus {
	border: 1px solid #49daf1 !important;
}

.edit-photo {
	cursor: pointer;
	display: block;
	color: white;
	filter: brightness(1.1);
	font-size: 14px;
	text-decoration: underline;
	font-weight: lighter;
	font-family: Helvetica;
	margin-left: 8px;
	font-size: 11px;
	width: fit-content;
}

.edit-photo > input {
	display: none;
}

.card-input {
	height: 18px;
	font-size: 10px;
	padding-top: 3px;
	margin-bottom: 8px;
	border: none;
	padding-left: 3px;
	background-color: var(--Darkblue-200);
	color: rgb(255, 255, 255);
}

.card-input:focus {
	outline: 1px solid #3adafd !important;
}

.card-label {
	font-weight: normal;
	color: white;
	font-size: 12px;
	text-transform: uppercase;
}

.card-bottom-button {
	position: absolute;
	bottom: -13px;
	right: 50%;
	transform: translate(50%);
	height: unset;
	padding: 2px 12px 0px 12px;
	z-index: 1;
}

.card-textarea-input {
	background-color: var(--Darkblue-200);
	color: rgb(255, 255, 255);
	resize: none;
	border: none;
	padding: 1px;
}

.card-textarea-input:focus {
	outline: 1px solid #49daf1 !important;
	padding: 0px;
}

.card-color-input {
	border-radius: 4px;
	width: 75px;
	padding-top: 3px;
	padding-left: 13px;
	background: url(../../../resources/img/blue-select-arrow.png) right center no-repeat rgb(0, 32, 57);
}

.card-color-input:focus {
	padding-top: 3px;
	padding-left: 13px;
}

.card-color-sample {
	pointer-events: none;
	position: relative;
	width: 8px;
	top: -23px;
	left: 3px;
	height: 8px;
}

.area-event-type-container {
	padding-left: 14%;
	padding-right: 14%;
}

.area-event-type-container > div {
	border-bottom: 3px dashed rgba(14, 191, 249, 0.15);
}

.area-event-type-container > div:nth-child(3n-2) {
	border-right: 3px dashed rgba(14, 191, 249, 0.15);
}

.area-event-type-container > div:nth-child(3n) {
	border-left: 3px dashed rgba(14, 191, 249, 0.15);
}
