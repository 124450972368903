.round-checkbox {
	border-radius: 15px;
	background-image: linear-gradient(254.88deg, #44e0fe 1.37%, #00a9e1 40.4%, #0091c0 99.49%);
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	align-content: center;
	justify-content: center;
	align-items: center;
	width: 18px;
	height: 18px;
	pointer-events: auto;
	cursor: pointer;
}
.round-checkbox-border {
	border-radius: 15px;
	background: #080809;
	width: 16px;
	height: 16px;
	border-radius: 15px;
}

.selected-inner {
	background: linear-gradient(to bottom, #44e0fe, #0388ba);
	width: 10px;
	height: 10px;
	border-radius: 15px;
	position: absolute;
}
.round-checkbox.disabled {
	background: #8b8b8b;
	border-radius: 15px;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	align-content: center;
	justify-content: center;
	align-items: center;
	width: 18px;
	height: 18px;
}
