.component-checkbox {
	position: relative;
	width: 16px;
	height: 16px;
	border: 1px solid;
	border-image-slice: 1;
	border-image-source: linear-gradient(254.88deg, #44e0fe 1.37%, #00a9e1 40.4%, #0091c0 99.49%);
	pointer-events: auto;
	cursor: pointer;
}
.component-checkbox.checked {
	background-image: linear-gradient(254.88deg, #44e0fe 1.37%, #00a9e1 40.4%, #0091c0 99.49%);
	box-shadow: inset 0px 0px 1px 0px rgb(80 80 80 / 50%);
}
.component-checkbox.disabled {
	background-image: none;
	box-shadow: none;
	border: 1px solid #8b8b8b;
	border-image-slice: unset;
	border-image-source: none;
	pointer-events: none;
}
.component-checkbox > img {
	position: absolute;
	top: 2px;
	left: 1.5px;
}
