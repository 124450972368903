.blue-plain-input {
	display: inline-block;
	background-color: var(--Darkblue-200);
	height: 29px;
	border-radius: 4px;
	width: 100%;
	padding-left: 8px;
	color: white;
	font-weight: 300;
	border: none;
}

.blue-plain-input:focus {
	border: 1px solid #3adafd !important;
	padding-left: 7px;
}

.margin-4 {
	margin: 4px auto !important;
}
