/* Atomic definitions for quick adjustments should be defined here */

.position-relative {
	position: relative;
}

.bottom-5px {
	bottom: 5px;
}

.text-center {
	text-align: center;
}

.width-8-percent {
	width: 8.3%;
}

.side-padding-15px {
	padding-left: 15px;
	padding-right: 15px;
}

.padding-top-30 {
	padding-top: 30px;
}

.display-inline-block {
	display: inline-block !important;
}

.width-200px {
	width: 200px !important;
}

.width-210px {
	width: 210px !important;
}

.width-240px {
	width: 240px !important;
}

.width-270px {
	width: 270px !important;
}

.width-300px {
	width: 300px !important;
}

.width-520px {
	width: 520px !important;
}

.force-uppercase {
	text-transform: uppercase;
}

.width-87px {
	width: 87px !important;
}

.min-height-unset {
	min-height: unset !important;
}

.height-24px {
	height: 24px !important;
}

.height-280px {
	height: 280px !important;
}

.color-white {
	color: white;
}

.font-size-12px {
	font-size: 12px;
}

.font-size-16px {
	font-size: 16px;
}

.font-size-17px {
	font-size: 17px;
}

.width-33p {
	width: 33%;
}

.box-sizing-border-box {
	box-sizing: border-box;
}

.margin-top-8 {
	margin-top: 8px;
}

.margin-right-12px {
	margin-right: 12px;
}

.margin-16 {
	margin: 16px;
}

.margin-bottom-20 {
	margin-bottom: 20px;
}

.margin-left-36 {
	margin-left: 36px;
}

.margin-left-auto {
	margin-left: auto;
}
.pointer {
	cursor: pointer;
}
.no-text-transform {
	text-transform: none;
}

.bold {
	font-weight: bold;
}

.button-disabled {
	box-shadow: 1px 1000px 1px #000 inset;
	opacity: 0.5;
	cursor: auto;
	pointer-events: none;
}

.divider {
	/* margin-top: 16px;
	margin-left: -50px; */
	margin: 8px 0 8px -50px;
	width: 160vw;
	height: 1px;
	background-color: #051c2a;
	content: "";
}

.rotate-180deg {
	transform: rotate(180deg);
}

.z-index-10 {
	z-index: 10;
}
.z-index-11 {
	z-index: 11;
}
.z-index-33 {
	z-index: 33;
}

.pointer-none {
	pointer-events: none;
}

.hover-layer {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	border-radius: 16px;
}

.hover-layer:hover {
	background-color: rgba(255, 255, 255, 0.05);
}

.relative {
	position: relative;
}
