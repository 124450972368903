html {
	height: 100%;
}

body {
	background: radial-gradient(95.03% 100% at 1.21% 0%, #112d41 0%, #041520 100%), #080809;
	font-family: Poppins;
	height: 100%;
	overflow: hidden;
	position: relative;
	min-height: 100%;
	margin: 0px;
	line-height: 1.42857143;
	font-size: 14px;
}

body:before {
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 0;
}

::placeholder,
:-ms-input-placeholder,
::-ms-input-placeholder {
	color: #8e8e8e;
}

textarea {
	font-family: inherit;
}

a:focus,
button:focus {
	outline: none;
}

.btn:hover,
a:hover {
	cursor: pointer;
}

.btn-default.active,
.btn-default.active:focus,
.btn-default.active:hover {
	background-color: #ababab;
}

.btn.transparent {
	opacity: 0.5;
}

.panel-default > .panel-heading > .row {
	padding-right: 5%;
}

.config-panel-gradient {
	background-color: var(--Darkblue-500);
}

ul.list-striped .custom-transparent {
	background-color: transparent;
	border: none;
}

.container {
	width: 100%;
	padding: 0;
	margin: 0;
}

button:focus,
input:focus,
textarea:focus,
select:focus,
option:focus {
	outline: none !important;
}

.main-button {
	text-transform: capitalize;
}

button > i.fa.sub,
a > i.fa.sub {
	position: absolute;
	margin-left: -0.3em;
	margin-top: 0.7em;
	font-size: 0.6em;
	text-shadow: -2px -1px 0px #fff;
}

.form-control:focus {
	border: 1px solid #3adafd !important;
}

.input-group-addon,
.input-group-btn {
	vertical-align: top;
}

input[type="checkbox"],
input[type="radio"] {
	margin: 0;
	height: 1.5em;
	width: 1.5em;
	display: inline-block;
}

input[type="checkbox"].form-control {
	box-shadow: none !important;
}

.panel {
	overflow: hidden;
	background-color: transparent;
}

.main-popup {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 4px;
	background-color: var(--Darkblue-300);
	z-index: 21;
	color: white;
}

ui-gmap-app-google-map,
.angular-app-google-map,
.video-feeds-panel {
	height: 100%;
}

.container.body .gmap-panel-body {
	height: calc(100vh - 73px);
	height: calc(var(--vh, 1vh) * 100 - 73px);
}

.container.body .video-feeds-panel .panel-body {
	height: calc(100vh - 73px);
	height: calc(var(--vh, 1vh) * 100 - 73px);
}

.container.body .social_feed_container {
	min-height: calc(100vh - 110px);
}

.container.body.missiondashboard3 .gmap-panel-body {
	height: calc(50vh - 57px);
}
.container.body.missiondashboard3 .video-feeds-panel .panel-body {
	height: calc(50vh - 57px);
}
.container.body.missiondashboard3 .social_feed_container {
	min-height: calc(50vh - 110px);
}

body > .body > ng-view > div > div.col-md-6 {
	width: 50% !important;
	float: left !important;
	overflow: hidden;
}

/* HOME */

@media (max-width: 900px) {
	.body.home .panel:first-of-type {
		width: 900px;
	}
}

.body.home ul.emergencies > li {
	/*  padding-left: 15em;  */
	text-transform: uppercase;
	color: white;
	font-size: 20px;
	padding: 0px;
}

.body.home ul.emergencies > li > h4 {
	display: inline-block;
	margin: 0.5em;
}

.body.home ul.emergencies > li > .label {
	/* position: absolute;
    top: 0;
    left: 0;
    margin: 0.9em 1.2em;
    float: left; */
	line-height: 1em;
	padding: 0.2em 0.6em 0.2em;
	font-size: 18px;
}

.body.home ul.emergencies > li > .event_mission_details {
	position: absolute;
	top: 0.4em;
	right: 0.4em;
	font-size: 18px;
	float: left;
	line-height: 1em;
	padding: 0.2em 0.6em 0.2em;
	border: 1px solid #ddd;
	border-radius: 4px;
	background: #f0f0f0;
}

.body.home ul.emergencies > li > .event_mission_details:before {
	position: absolute;
	display: block;
	content: "";
	height: 100%;
	width: 1.5em;
	top: 0;
	right: 100%;
	margin-right: 1px;
	z-index: 0;
	background: linear-gradient(to left, #fefefe 0%, #fefefe 20%, transparent 100%);
}

.closed-emergency-item {
	text-transform: uppercase;
	color: white;
	font-size: 20px;
	padding: 0px;
}

.closed-emergency-item:hover {
	filter: brightness(1.5);
	cursor: pointer;
}

/* LOADING ANIMATION */
.body .loading_spinner {
	position: fixed;
	bottom: 6%;
	right: 4%;
	font-size: 33px;
	height: -1px;
	pointer-events: none;
	overflow: visible;
	z-index: 20;
}

.input-group.username-input .form-control,
.input-group.hashtag-input .form-control {
	padding-left: 2em;
}
.input-group.username-input > i,
.input-group.hashtag-input > i {
	position: absolute;
	z-index: 10;
	padding: 0.7em 0.7em;
	left: 0;
}
.confirm_modal_header {
	padding: 50px 60px;
}
.confirm_modal_header .close_modal {
	position: absolute;
	top: 3px;
	right: 5px;
	font-size: 24px;
}
.confirm_modal_header .close_modal:hover {
	cursor: pointer;
}

/* ICONS */
.gmapitemsmenu .svg-include,
.gmapitemsmenu .dynamic_icon {
	height: 1em;
	width: 1em;
}

/* HISTORY */

.gmap-panel-body .history_list {
	position: absolute;
	top: 1em;
	right: 2em;
	text-align: right;
	left: auto !important;
}

.missionedit .history_list {
	position: absolute;
	top: 0.3em;
	right: 4em;
	text-align: right;
	left: auto !important;
}

.gmap-panel-body .history_list > ul {
	text-align: center;
	max-height: 350px;
	overflow-y: auto;
}

.gmap-panel-body .history_list > ul > li > button {
	font-size: 14px;
}

.playback_selector {
	margin: 13px 15px;
}

playback-timeline {
	position: fixed;
	left: 20px;
	bottom: 20px;
	width: 100%;
	width: calc(100% - 40px);
	height: 20px;
	margin: 0;
	padding: 0;
	z-index: 9999999;
}

#playback_timeline {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #333;
	transition: transform 600ms ease-in-out;
}

#playback_timeline:before,
#playback_timeline:after {
	z-index: -1;
	content: "";
	display: block;
	position: absolute;
	top: 0;
	height: 20px;
	width: 20px;
	border-radius: 10px;
	background: #333;
}

#playback_timeline:before {
	right: -10px;
}

#playback_timeline:after {
	left: -10px;
}

#playback_timeline:hover {
	background: transparent radial-gradient(ellipse 20px 150% at calc(var(--mouse-x, 0) * 100%) calc(var(--mouse-y, 0) * 100%), gray, #333) no-repeat 0 0;
}

#playback_timeline.hidebelow {
	transform: translateY(110px);
}

#playblack_timeline_clickable {
	width: 100%;
	height: 100%;
	z-index: 100;
}

#playback_timeline_cursor {
	position: absolute;
	top: -8px;
	height: 36px;
	width: 12px;
	margin-left: -8px;
	border-radius: 10%;
	border: 2px outset #cfcfcf;
	z-index: 10;
}

#playback_timeline_cursor:hover {
	border: 2px outset #dfdfdf;
}

#playback_timeline_cursor.silver {
	background: linear-gradient(45deg, rgba(160, 160, 160, 1) 0%, rgba(232, 232, 232, 1) 56%);
}

#playback_timeline_cursor.silver:before {
	background: linear-gradient(45deg, rgba(181, 181, 181, 1) 0%, rgba(252, 252, 252, 1) 56%, rgba(232, 232, 232, 1) 96%);
	border: 1px solid rgba(181, 181, 181, 1);
}

.playback_change_mark {
	position: absolute;
	top: 0;
	height: 100%;
	width: 2px;
	margin-left: -1px;
	background-color: #f0e442;
	z-index: 0;
}

#playback_timeline .playback_timeline_time {
	position: absolute;
	top: 0;
	margin-top: -20px;
	z-index: 1;
}

#playback_timeline .playback_timeline_time.start {
	left: 4px;
}

#playback_timeline .playback_timeline_time.end {
	right: 4px;
}

#playback_timeline .playback_timeline_time.current {
	font-size: 13px;
	margin-top: 22px;
}

.playback_btn {
	width: 40px;
	height: 40px;
	border-radius: 20px;
	top: -50px;
	left: 50%;
	position: absolute;
	background: #333;
	color: #fff;
	border: none;
	z-index: 1;
}

#playback_previous_change {
	margin-left: -70px;
}

#playback_pause_play {
	margin-left: -20px;
}

#playback_next_change {
	margin-left: 30px;
}

#playback_change_speed {
	margin-left: 80px;
	width: 30px;
	height: 30px;
}

#playback_loading {
	position: fixed;
	display: block;
	top: 50%;
	left: 50%;
	padding: 2em 1em 1em 1em;
	width: 24em;
	margin-top: -4em;
	margin-left: -12em;
	background: #cb8a31;
	border: 1px solid #bd7719;
	color: #fff;
	box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
	font-size: 20px;
	font-weight: bold;
	text-align: center;
}
#playback_loading i {
	display: block;
	position: relative;
	margin: 0.5em auto;
}
.row.disabledRow {
	color: #ccc;
}

.homepage-list-header {
	margin-top: 3px;
}
.select-resource-list {
	max-height: 80%;
	overflow: auto;
	top: 12%;
	right: 1.5%;
	position: relative;
	margin-top: 8%;
	left: 4.5%;
	width: 90%;
}

.gray-layer {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
}

.mission-config-box {
	border-radius: 4px;
	border: 1px solid gray;
	position: absolute;
	top: 12%;
	bottom: 12%;
	left: 20%;
	right: 20%;
	background-color: white;
}

.type-table-list {
	right: 3.4%;
	bottom: 3.2%;
	overflow: auto;
	height: 140px;
}

.mission-config-box-top {
	top: 3%;
	left: 1.5%;
	right: 1.5%;
	position: relative;
}
.mission-config-box-header {
	border-bottom: 1px solid lightgray;
	width: 95%;
	font-size: 24px;
	margin-top: 2%;
	margin-left: 2%;
}

.mission-config-box-subheader {
	margin-top: 20px;
	margin-left: 20px;
}
.type-table-button-div {
	position: absolute;
	width: 24%;
	right: 3.4%;
	bottom: 3.2%;
}
/* magnifying glass icon for homepage searchbar since fa 4.7 doesn't have one */
@import "compass/css3";

.ico-mglass {
	position: relative;
	display: inline-block;
	background: #fff;
	border-radius: 30px;
	height: 9px;
	width: 9px;
	border: 2px solid #888;
}
.ico-mglass:after {
	content: "";
	height: 2px;
	width: 6px;
	background: #888;
	position: absolute;
	top: 7px;
	left: 5px;
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
}

.width-80 {
	width: 80%;
}

.width-100 {
	width: 100%;
}

.margin-bottom-15p {
	margin-bottom: 15px;
}

.margin-bottom-8 {
	margin-bottom: 8px;
}

.margin-0 {
	margin: 0;
}

.main-checkbox.main-dropdown-checkbox {
	float: left;
	margin-right: 16px;
	margin-left: 12px;
}

.main-checkbox > i {
	left: 0px;
	bottom: 1px;
}

.main-dropdown-text {
	padding-top: 8px;
	padding-left: 10px;
	border-bottom: 1px solid var(--Blue-200);
	height: 37px;
	cursor: pointer;
	width: 100%;
	white-space: nowrap;
}

.main-dropdown.disabled > .main-dropdown-text,
.main-dropdown.disabled {
	border-bottom: 1px solid #8b8b8b !important;
	color: #8b8b8b !important;
	pointer-events: none !important;
	opacity: 0.5 !important;
}

.main-blue-mask {
	width: 100%;
	height: 100%;
	opacity: 0.69;
	background-color: #07283c;
	position: fixed;
	z-index: 1;
	bottom: 0;
	left: 0;
}

.main-dropdown-text > span {
	width: calc(100% - 33px);
	display: inline-block;
	overflow: hidden;
	color: var(--Gray-100);
}

.main-dropdown-text.writable > span {
	cursor: text;
}

.main-dropdown-text > .search-input {
	cursor: text;
	width: calc(100% - 33px);
	display: inline-block;
	overflow: hidden;
	border: none;
	background: none;
	padding: 0;
	vertical-align: top;
}

.main-arrow-mask {
	display: inline-block;
	width: 22px;
	height: 25px;
	cursor: pointer;
	text-align: center;
	position: relative;
	bottom: 5px;
	transform: scale(0.8);
}

.main-arrow-mask > div {
	display: inline-block;
}

.main-cross-button {
	background-color: transparent;
	width: 7px;
	height: 16px;
	padding: 0px 15px 0 0;
	border: none;
	transform: rotate(0deg);
	position: relative;
	bottom: 6px;
	cursor: pointer;
}

.main-cross-button.disabled {
	pointer-events: none;
	cursor: not-allowed;
}

.cancel-button.calendar {
	display: flex;
	justify-content: flex-end;
	margin-top: 13px;
	margin-right: 8px;
	margin-bottom: -16px;
}

.main-cross-button.delete:hover > span {
	background-color: var(--Blue-200);
}

.main-cross-button.delete {
	transform: rotate(45deg);
	bottom: 4px;
}

.main-cross-button > span {
	position: absolute;
	width: 12px;
	height: 2px;
	background-color: var(--Gray-100);
	transition: 0.15s transform ease-out;
}

.main-cross-button.white > span {
	background-color: var(--Gray-100);
}

.main-cross-button.disabled > span {
	background-color: rgb(255 255 255 / 50%) !important;
}

.main-cross-button > span:nth-child(2) {
	transform: rotate(90deg);
}

.main-cross-button.large > span {
	width: 16px;
}

.vertical-align-top {
	vertical-align: top;
}

.main-cross {
	cursor: pointer;
	height: 16px;
	position: absolute;
	right: 26px;
	top: 39px;
	width: 16px;
}
.main-cross > div {
	background: #ffffff;
	border-radius: 1px;
	height: 2px;
	position: absolute;
	width: 20px;
	z-index: 22;
}
.main-cross > div:nth-child(1) {
	transform: rotate(45deg);
}
.main-cross > div:nth-child(2) {
	transform: rotate(-45deg);
}

.main-table {
	color: white;
	font-size: 16px;
	font-weight: 400;
	height: 100%;
	width: 100%;
}
.main-table-row {
	background: var(--Darkblue-400);
	width: 100%;
}
.main-table-row.header {
	background: var(--Darkblue-200);
	border-radius: 3px 3px 0 0;
	font-weight: 500;
	height: 56px;
}
.main-table-cell {
	display: inline-flex;
	padding: 9px 4px 9px 32px;
}

.main-table-row.header .main-table-cell {
	padding: 17px 4px 17px 32px;
}
.main-table-row.content .main-table-cell {
	border-right: 1px solid #2c3043;
	height: 100%;
}
.main-table-content-wrapper > :nth-child(even) {
	background: #092536;
}
.main-table-row.content .main-table-cell:last-child {
	border: none;
}
.main-table-content-wrapper {
	max-height: calc(100% - 142px);
	overflow-y: overlay;
	width: calc(100% + 14px);
}
.main-table-row.content {
	width: calc(100% - 14px);
}

.main-triangle {
	cursor: pointer; /*atomic*/
	width: 0;
	height: 0;
	position: relative;
	transition: transform 0.3s ease;
}
.main-triangle.up {
	border-bottom: 9px solid var(--Blue-200);
	border-left: 9px solid transparent;
	border-right: 9px solid transparent;
}
.main-triangle.down {
	border-left: 9px solid transparent;
	border-right: 9px solid transparent;
	border-top: 9px solid var(--Blue-200);
}
.main-triangle.right {
	border-bottom: 9px solid transparent;
	border-left: 9px solid var(--Blue-200);
	border-top: 9px solid transparent;
}
.main-triangle.left {
	border-bottom: 9px solid transparent;
	border-right: 9px solid var(--Blue-200);
	border-top: 9px solid transparent;
}

.main-triangle.down:hover:not(:disabled) {
	transform: translateY(4px);
}

.main-triangle.up:hover:not(:disabled) {
	transform: translateY(-4px);
}

.main-no-visivility {
	visibility: hidden;
}

.main-signature-field {
	display: block;
	margin-top: 2%;
	height: 40%;
	max-height: 140px;
	min-height: 90px;
	width: 100%;
	background-color: var(--Darkblue-300);
	border: none;
	cursor: crosshair;
	position: relative;
}

.main-signature-undo {
	position: absolute;
	color: white;
	font-size: 20px;
	cursor: pointer;
	bottom: 8px;
	right: 8px;
	z-index: 1;
}

.main-signature-undo.inactive {
	color: var(--Gray-200);
	pointer-events: none;
}

.regular-button {
	padding: 8px 16px !important;
}

.main-blue-gradient-button-disabled:hover {
	background: var(--Blue-300);
}

.mb {
	margin-bottom: 7px !important;
}

.font14 {
	font-size: 14px !important;
}

.hardware-field {
	display: flex;
	margin: 2px auto;
	flex-wrap: wrap;
}

.main-blue-gradient-button.large {
	padding: 7px 39px;
}

.main-blue-gradient-button.cancel {
	background-image: linear-gradient(to bottom, #44d1fe, #00394b);
}

.main-alternate-button:hover {
	border: solid 1.5px var(--Blue-200);
	background-color: rgba(0, 145, 192, 0.2);
}

.main-blue-gradient-button:active {
	background: #0091c0;
}

.main-blue-gradient-button.active {
	opacity: 0.9;
}

.main-blue-gradient-button.config {
	padding: 2px 12px 0px 12px;
	height: 30px;
}

.display-flex {
	display: flex;
	align-content: center;
	justify-content: center;
	align-items: center;
}

.main-blue-gradient-button input[type="file"] {
	display: none;
}

.transparent-input {
	border: none;
	background: transparent;
}

.main-form-container {
	display: flex;
	height: 293px;
	flex-direction: column;
	justify-content: space-between;
	max-height: 584px;
	width: 410px;
	height: 71%;
	border: 0px;
	margin: 0px;
	padding: 0px;
}

.main-transparent-mask {
	width: 100%;
	height: 100%;
	opacity: 0.69;
	background-color: transparent;
	position: fixed;
	z-index: 9;
	bottom: 0;
	left: 0;
}

.main-black-mask {
	width: 100%;
	height: 100%;
	opacity: 0.6;
	background-color: black;
	position: fixed;
	z-index: 32;
	bottom: 0;
	left: 0;
}

.black-mask-transparent {
	opacity: 0;
}

.main-dropdown > .menu.dopen {
	opacity: 1 !important;
	pointer-events: all !important;
}

.main-wide-button {
	padding: 7px 11px;
	letter-spacing: 0.04em;
	margin: 10px;
	min-width: 92px;
	height: 38px;
}

.main-wide-button.disabled {
	pointer-events: none;
	cursor: not-allowed;
	opacity: 0.5;
	cursor: not-allowed;
}

.main-alternate-button {
	background: none;
	border: 1px solid var(--Blue-200);
	height: 38px;
	border-radius: 4px;
	color: white;
}

.margin-top-10 {
	margin-top: 10px;
}

.margin-right-16 {
	margin-right: 16px;
}

.overflow-text {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.borderless {
	border: none !important;
}
.no-position {
	bottom: 0px;
	top: 0px;
	left: 0px;
	right: 0px;
}
.overflow-invisible {
	overflow: hidden !important;
}
@media (max-width: 1550px) {
	.main-dropdown-text > .search-input {
		cursor: text;
		display: inline-block;
		overflow: hidden;
		border: none;
		background: none;
		padding: 0;
	}
}

.homepage-list-searchbox {
	width: 97%;
	border: 1px solid lightgray;
	border-radius: 4px;
	padding-left: 6%;
}

.searchbox-glass {
	margin-bottom: 2px;
	float: left;
	position: relative;
	left: -1%;
	top: 0%;
	margin-top: 4px;
}

.searchbox-glass-container {
	background-color: transparent;
	position: absolute;
	width: 25px;
	height: 25px;
	z-index: 5;
	border: none;
	cursor: default;
	padding-bottom: 20px;
}

.homepage-sorter {
	cursor: pointer;
	color: lightgray;
}

.config-column {
	padding-top: 10px;
}

.ics-log-tab {
	box-shadow: 0px 0px 13px 1px black !important;
}

.custom-hover:hover {
	filter: brightness(3);
}

.event-container:button {
	position: absolute;
	right: 85px;
	bottom: 28px;
}

::-webkit-scrollbar {
	width: 8px;
	height: 7px;
	border-radius: 4px;
}

::-webkit-scrollbar-track {
	background-color: transparent;
}

::-webkit-scrollbar-thumb {
	background: rgba(80, 95, 106, 0.3);
	border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
	filter: brightness(3);
}
::-webkit-scrollbar-corner {
	background: transparent;
}

#low-text {
	position: fixed;
	bottom: 0%;
	right: 0%;
	background-color: black;
	width: 18%;
	border-radius: 4px;
	padding-left: 5px;
	border: 1px solid lightgray;
	border-bottom: none;
	border-right: none;
	border-bottom-left-radius: 0px;
	border-top-right-radius: 0px;
	z-index: 30;
}

#low-text > span {
	float: right;
	padding-right: 5px;
	color: white;
}

.pback-header {
	position: absolute;
	z-index: 25;
	top: 0px;
	left: 0px;
	width: 110px;
	height: 51px;
}

.pback-header > img {
	width: 50px;
	position: absolute;
	top: 8px;
	left: 50px;
	cursor: pointer;
}

.drag-bar {
	height: calc(100% - 73px);
	border: 1px solid #ee490f;
	width: 19px;
	display: inline-block;
	cursor: ew-resize;
	background-color: black;
	opacity: 0.8;
	position: absolute;
	bottom: 0;
	z-index: 20;
}

.drag-bar > span:nth-child(1) {
	background-color: #ee490f;
	height: 3px;
	width: 40px;
	position: absolute;
	top: 50%;
	left: -9px;
	transform: rotate(90deg);
}

.drag-bar > span:nth-child(2) {
	background-color: #ee490f;
	height: 3px;
	width: 28px;
	position: absolute;
	top: 50%;
	left: -9px;
	transform: rotate(90deg);
}

.drag-tab-triangle {
	position: absolute;
	width: 13px;
	height: 16px;
	top: 50%;
	overflow: visible !important;
	left: 50%;
	transform: translate(-50%, -50%);
}

.drag-bar > .left-tab {
	bottom: -1px;
	right: 17px;
	width: 28px;
	height: 40px;
	background-color: black;
	border: 1px solid #ee490f;
	position: absolute;
	border-right: 0px;
	cursor: pointer;
}

.drag-bar > .right-tab {
	bottom: 30px;
	right: -28px;
	width: 28px;
	height: 40px;
	background-color: black;
	border: 1px solid #ee490f;
	position: absolute;
	border-left: 0px;
	cursor: pointer;
}

.form-control,
.form-control[disabled] {
	background-color: #072a3f;
	height: 35px;
	color: white;
	border: none;
	border-radius: 2px;
}

.force-background {
	background-color: #072a3f !important;
}

.new-item-notification {
	position: fixed;
	top: 55px;
	text-align: center;
	pointer-events: none;
	z-index: 8;
	bottom: -9px;
	width: 20px;
	height: 20px;
	border-radius: 1000px;
	font-size: 15px;
	font-weight: normal;
	color: white;
	background-image: linear-gradient(to bottom, #fd9e1e, #f9650d);
}

/* input focus "take out" placeholder edit */
input:focus::-webkit-input-placeholder {
	color: transparent;
}
input:focus:-moz-placeholder {
	color: transparent;
} /* FF 4-18 */
input:focus::-moz-placeholder {
	color: transparent;
} /* FF 19+ */
input:focus:-ms-input-placeholder {
	color: transparent;
} /* IE 10+ */

/* spin button for number-type inputs */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	/* display: none; <- Crashes Chrome on hover */
	-webkit-appearance: none;
	appearance: none;
	margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
	-moz-appearance: textfield; /* Firefox */
	appearance: textfield;
}

.col-md-1 {
	width: 8.33333333% !important;
	float: left !important;
}
.col-md-2 {
	width: 16.67% !important;
	float: left !important;
}

.col-md-3 {
	width: 25% !important;
	float: left !important;
}

.col-md-4 {
	width: 33% !important;
	float: left !important;
}

.col-md-5 {
	width: 41.66666667% !important;
	float: left !important;
}
.col-md-6 {
	width: 50% !important;
	float: left !important;
}

.agent-picker {
	position: fixed;
	z-index: 4;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 855px;
	height: 548px;
	box-shadow: 2px 0 5px 2px rgba(0, 0, 0, 0.5);
	background-color: #020a0e;
}

.agent-picker > .tab-section {
	background-color: #061720;
	width: 100%;
	height: 10%;
}

.agent-picker > .tab-section > div {
	display: inline-block;
	height: 100%;
	width: 18%;
	color: white;
	font-size: 21px;
	text-align: center;
	padding-top: 15px;
	padding-bottom: 4px;
	text-transform: uppercase;
	cursor: pointer;
}

.agent-picker > .tab-section > .tab-inactive {
	color: #bcbbbb !important;
}

.agent-picker > .tab-section > .tab-active {
	background-color: #020a0e;
	border-top: 4px solid #3adafd;
	padding-top: 11px;
}

.agent-picker > .body-section {
	background-color: #020a0e;
	height: 90%;
	width: 100%;
}

.agent-picker > .body-section > .search-and-filters {
	background-color: #020a0e;
	height: 17%;
	color: white;
	padding: 5% 4% 0px 4%;
}

.agent-picker > .body-section > .search-and-filters > div,
.agent-picker > .body-section > .search-and-filters > input {
	display: inline-block;
	vertical-align: top;
}

.agent-picker > .body-section > .search-and-filters > .name-filter {
	background-color: #051c2a;
	font-size: 20px;
	color: white;
	padding-left: 10px;
	width: 20%;
	text-transform: uppercase;
	position: relative;
	bottom: 6px;
	margin-right: 2%;
}

.agent-picker > .body-section > .resource-list {
	background-image: linear-gradient(to bottom, #051c2a 28%, #000406 118%);
	height: 83%;
}

.white-placeholder::placeholder {
	color: white;
}

.glass-icon-black {
	background-color: black;
	width: 23px;
	height: 23px;
	margin-bottom: -5px;
	margin-right: 0px;
	display: inline-block;
	pointer-events: none;
}

.glass-icon-black > div:nth-child(1) {
	border-radius: 100%;
	background: transparent;
	border: 1px solid white;
	width: 14px;
	height: 14px;
	position: relative;
	top: 2px;
	left: 6px;
}

.glass-icon-black > div:nth-child(2) {
	background-color: white;
	width: 9px;
	height: 2px;
	transform: rotate(-45deg);
	position: relative;
	top: 2px;
	left: 1px;
}

.agent-picker-select {
	background-color: #051c2a !important;
	height: unset !important;
	width: 143px !important;
	position: relative;
	bottom: 9px;
	padding: 0px !important;
	border-radius: 0px !important;
	padding-left: 5px !important;
}

.agent-picker-submenu {
	list-style-type: none;
	z-index: 4;
	position: absolute;
	width: inherit;
	height: auto;
	text-align: left;
	display: block;
	top: 29px;
	left: 0px;
	text-transform: uppercase;
	color: white;
	border: none;
	background-color: #051c2a;
	font-size: 20px;
	max-height: 426px;
	overflow-y: auto;
}

.agent-picker-submenu > li:hover {
	background-color: #0b83aa;
}

.agent-picker-item {
	list-style: none;
	color: white;
	min-height: 40px;
	padding-top: 2px;
	padding-left: 34px;
	cursor: pointer;
}

.agent-picker-item:hover {
	background-color: rgb(30, 91, 132, 0.5);
}

.agent-picker-item > div {
	display: inline-block;
	vertical-align: top;
}

.agent-picker-mask {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: 4;
	opacity: 0.69;
	background-color: #07283c;
}

li > span {
	max-width: 100%;
	word-break: break-word;
	white-space: normal;
}

.inline-block {
	display: inline-block;
}

.opaque {
	opacity: 1 !important;
}
.main-dropdown {
	color: white;
	width: 197px;
	transition: 0.25s background-color ease-in-out;
}

.main-dropdown.open {
	position: relative;
	z-index: 9;
	background-color: var(--Darkblue-400);
	filter: drop-shadow(0px 5px 5px #063246);
}

.alert-mask {
	z-index: 9999 !important;
}

.main-alert-box {
	background: var(--Darkblue-300);
	mix-blend-mode: normal;
	box-shadow: 0px 8px 24px rgb(0 0 0 / 25%);
	border-radius: 8px;
	width: 332px;
	height: 196px;
	position: fixed;
	z-index: 9999;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: white;
	text-align: center;
	padding: 15px 50px !important;
}

.main-alert-box > .cancel-button {
	position: absolute;
	top: 10px;
	right: 10px;
}

.main-alert-box > .header {
	width: 100%;
	height: 35px;
	font-size: 18px;
}

.main-alert-box > .body {
	font-size: 14px;
	margin-top: 10px;
}

.main-alert-box > .icon-container {
	width: 100%;
	text-align: center;
}

.main-alert-box > .icon-container > .icon {
	background-color: var(--Blue-200);
	color: white;
	width: 30px;
	height: 30px;
	border-radius: 30px;
	font-size: 18px;
	padding: 3px;
	display: inline-block;
}

.main-alert-box > .text {
	width: 100%;
	padding: 10px 0;
	font-size: 14px;
}

.main-alert-box > .text.centered {
	width: 100%;
	padding: 10px 0;
	margin-top: 40px;
	font-size: 14px;
}
.main-alert-box > .text .ara {
	margin-top: 0;
	color: var(--Blue-200);
	text-decoration-line: underline;
	display: inline;
	cursor: pointer;
}

.main-alert-box > .footer {
	position: absolute;
	bottom: 16px;
	left: 50%;
	white-space: nowrap;
	transform: translate(-50%, 0);
	display: flex;
}

.main-alert-box > .footer > button {
	margin: 0 10px;
}

.main-unselectable {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.main-tabset {
	height: 56px;
	width: 100%;
	background-color: var(--Darkblue-100);
}

.main-modal-panel {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 3;
	padding: 12px;
	transform: translate(-50%, -50%);
	width: 33%;
	text-align: center;
	background-image: linear-gradient(181deg, #0c4a6c, #01090a 13%, #000506);
}
.camera-rtmpurl {
	color: white;
	font-size: 9px;
	margin: 4px 3px 0 4px;
	overflow-y: auto;
}
.cam-field-label {
	display: inline-block;
	font-size: 11px;
	color: white;
	margin-left: 6px;
}
.camera-url-input.ims {
	margin: 3px 0 4px 11px;
	display: inline-block;
	width: 108px;
}
.max-height-99 {
	max-height: 99%;
}

.popup-large {
	height: 245px !important;
	padding: 24px 40px !important;
}

.main-skill-checkbox {
	float: right;
	margin-top: 12px;
	width: 16px;
	height: 16px;
}

.error-orange-border {
	border-top: 0.5px solid var(--Orange-100);
	border-bottom: 0.5px solid var(--Orange-100) !important;
	border-left: 0.5px solid var(--Orange-100) !important;
	border-right: 0.5px solid var(--Orange-100) !important;
}
.error-orange-border:focus {
	border-top: unset;
	border-bottom: 0.5px solid var(--Orange-100) !important;
	border-left: 0.5px solid var(--Orange-100) !important;
	border-right: 0.5px solid var(--Orange-100) !important;
}
.error-orange-border + label {
	color: var(--Orange-100) !important;
}
.error-orange-border.solid-orange:focus {
	border-top: unset !important;
	border-bottom: 0.5px solid var(--Orange-100) !important;
	border-left: 0.5px solid var(--Orange-100) !important;
	border-right: 0.5px solid var(--Orange-100) !important;
}

.error-orange-border + label::before {
	background-color: var(--Orange-100) !important;
}
.error-orange-border:focus + label::before {
	background-color: var(--Orange-100) !important;
}

.error-orange-border + label::after {
	background-color: var(--Orange-100) !important;
}
.error-orange-border:focus + label::after {
	background-color: var(--Orange-100) !important;
}

.opaque {
	opacity: 1 !important;
}

.translucent {
	opacity: 0.7 !important;
}

.button-header {
	position: relative;
	display: block;
	padding: 20px 25px 10px;
	margin: 0px 34px 30px 8px;
}

.button-header > button {
	min-width: 74px;
}

app-transparent-dropdown {
	width: 100%;
	height: 100%;
}

.svg-icon-preview {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 24px;
	height: 24px;
	background: transparent;
	border: none;
}

.small-icon-preview > .dynamic_icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 24px;
	height: 24px;
}

.icon-preview {
	padding: 0.3em;
	width: 3em;
	height: 3em;
	background: transparent;
	border: none;
}

.icon-preview.big {
	width: 64px;
	height: 64px;
}

.icon-preview > svg {
	width: inherit;
	height: inherit;
}

.select-photo {
	background-color: #002039;
	width: 45px;
	height: 57px;
	margin: 0 0 16.38px 0;
	overflow: hidden;
	padding: 3px 11px;
	text-align: center;
	color: white;
	font-size: 12px;
	text-decoration: underline;
	cursor: pointer;
	line-height: 1.2;
	font-weight: normal;
	display: inline-block;
}

.select-photo > input {
	display: none;
}

* {
	user-select: none;
}

input,
textarea {
	user-select: all;
}

/* Filters and sort */

.sort-arrow {
	background-image: url(src/resources/img/Homepage-sort-arrows-off.svg);
	background-size: 16px 16px;
	width: 16px;
	height: 16px;
	margin-left: 8px;
	display: inline-block;
	vertical-align: middle;
	cursor: pointer;
}
.sort-arrow:hover {
	background-image: url(src/resources/img/Homepage-sort-arrows-off-hover.svg);
}

.sort-arrow.asc {
	background-image: url(src/resources/img/Homepage-sort-arrows-active-asc.svg);
}
.sort-arrow.asc:hover {
	background-image: url(src/resources/img/Homepage-sort-arrows-asc-hover.svg);
}

.sort-arrow.desc {
	background-image: url(src/resources/img/Homepage-sort-arrows-active-desc.svg);
}
.sort-arrow.desc:hover {
	background-image: url(src/resources/img/Homepage-sort-arrows-desc-hover.svg);
}

.funnel {
	background-image: url(src/resources/img/funnel-off.svg);
	background-size: 16px 16px;
	width: 16px;
	height: 16px;
	margin-left: 8px;
	display: inline-block;
	vertical-align: middle;
	cursor: default;
	pointer-events: none;
}

.funnel.open {
	background-image: url(src/resources/img/funnel-active.svg);
	cursor: pointer;
	pointer-events: auto;
}

.funnel.default {
	background-image: url(src/resources/img/funnel-default.svg);
	cursor: pointer;
	pointer-events: auto;
}

.funnel.open:hover,
.funnel.default:hover {
	background-image: url(src/resources/img/funnel-hover.svg);
}

/* EOF Filters and sort */
