.black-plain-input {
	display: block;
	padding: 6px 13px;
	background-color: black;
	color: white;
	height: 35px;
	width: 100%;
	border: none;
	border-radius: 2px;
	margin-bottom: 15px;
}
