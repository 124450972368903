input {
	box-sizing: border-box;
	font-family: inherit;
}

input.disabled,
input:disabled {
	cursor: not-allowed;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-animation: autofill 0s forwards;
	animation: autofill 0s forwards;
	-webkit-text-fill-color: #fff !important;
}

@keyframes autofill {
	100% {
		background: transparent;
		color: inherit;
	}
}

@-webkit-keyframes autofill {
	100% {
		background: transparent;
		color: inherit;
	}
}
