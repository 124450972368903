.login-panel {
	margin-top: 150px;
	overflow: hidden;
}
.login-panel .panel-title {
	text-align: center;
	font-size: 18px;
}
.login-panel .login-submit button {
	font-weight: bolder;
}
.login-panel .login-mission label {
	display: block;
	text-align: center;
}
.login-panel .login-mission select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
.login-panel .login-mission select::-ms-expand {
	/* for IE 11 */
	display: none;
}

.login-container {
	text-align: center;
	position: absolute;
	top: 25%;
	left: 40%;
	width: 304px;
	font-size: 25px;
}

.login-text {
	font-size: 25px !important;
}

.login-arrow {
	position: absolute;
	top: 126px;
	right: 0px;
	cursor: pointer;
	width: 46px !important;
}

.margin-bottom-80 {
	margin-bottom: 80px;
}
